import { Model } from "../lib";
import { computed } from "mobx";
import { titleize } from "../lib/helpers";

export default class Activity extends Model {
  urlRoot = "/events";

  @computed
  get resourceWebURI() {
    if (this.resource_type === "variants") {
      return `/app/inventory/variants/${this.resource_id}`;
    }

    if (
      this.resource_type === "transfers" ||
      this.resource_type === "pickups"
    ) {
      return `/app/transfers/${this.resource_id}`;
    }

    if (this.resource_type === "stores") {
      return `/app/stores/${this.resource_id}`;
    }

    return `/app/${this.resource_type}/${this.resource_id}`;
  }

  @computed
  get typeText() {
    const types = {
      orders: "pedido",
      products: "producto",
      transfers: "ingreso",
      pickups: "recolección",
      stocks: "stock",
      stores: "tienda",
      publications: "publicación",
      users: "usuario",
      variants: "variante",
    };
    return types[this.resource_type];
  }

  @computed
  get resourceReference() {
    return this.resource
      ? this.resource.external_reference_user ||
          this.resource.external_reference ||
          this.resource.id
      : this.resource_id;
  }

  @computed
  get isOutOfStock() {
    return (
      this.whodunit.store.integration_type === "wms" &&
      this.changes &&
      this.changes.status &&
      this.changes.status[1] === "on_hold"
    );
  }

  @computed
  get who() {
    if (!this.whodunit) return null;
    const loggedInUser = this._store.appStore.loggedInUser;

    if (this.whodunit.user) {
      const user = this._store.appStore.users.getFromStoredData(
        this.whodunit.user
      );

      if (user.email === "hola@oyga.me") return null;

      if (user.email === "pvx@shipnow.com.ar" && loggedInUser.isWhsOwner()) {
        return "PVX";
      }

      if (user.isAdmin() && !loggedInUser.isAdmin()) {
        return "shipnow";
      }

      const name =
        user.name || user.last_name
          ? `${user.name || ""}  ${user.last_name || ""}`
          : user.email;

      return titleize(name);
    }

    if (this.whodunit.store) {
      if (this.isOutOfStock) {
        return "falta de stock";
      }

      if (this.whodunit.store.integration_type === "wms") {
        if (loggedInUser.isWhsOwner()) {
          return "WMS";
        } else {
          return "Shipnow";
        }
      }

      return "tu tienda";
    }
    if (this.whodunit.carrier) {
      return this.whodunit.carrier.name;
    }

    return null;
  }

  @computed
  get whoCarrier() {
    if (!this.whodunit || !this.whodunit.carrier) return null;

    return this.whodunit.carrier.name;
  }

  @computed
  get cookedWho() {
    return this.who || "Se";
  }

  isManualCreation() {
    return this.whodunit?.user;
  }
}
