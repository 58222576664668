import React from "react";
import withStore from "../../../hocs/withStore";
import { observer } from "mobx-react";

import PropTypes from "prop-types";

import { Select, SelectOption, Avatar, TextV2 } from "shipnow-mercurio";

const CarrierSelect = observer((props) => {
  const handleChange = (sender, value, name) => {
    props.onChange && props.onChange(sender, value, name);
  };

  const carrierOptions = () => {
    return new Promise((resolve) => {
      props.store.carriers.search({ per_page: 50 }).andThen((results) => {
        resolve(
          results.toArray().map((carrier) => (
            <SelectOption
              value={carrier.toJS()}
              display={carrier.name}
              key={carrier.id}
              onChange={handleChange}
            >
              <div className="level">
                <div className="level-left">
                  <span className="level-item">
                    <Avatar size="xs" image={carrier.image_url || "invalid"} />
                  </span>
                  <span className="level-item">
                    <TextV2>{carrier.name}</TextV2>
                  </span>
                </div>
              </div>
            </SelectOption>
          ))
        );
      });
    });
  };

  const { label, name, maxHeightOptions } = props;
  return (
    <Select
      maxHeightOptions={maxHeightOptions}
      label={label}
      name={name}
      options={carrierOptions}
      onChange={handleChange}
    />
  );
});

CarrierSelect.propTypes = {
  onChange: PropTypes.func,
  warehouseSystemOnly: PropTypes.bool,
  value: PropTypes.any,
  maxHeightOptions: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
};

CarrierSelect.defaultProps = {
  onChange: null,
  warehouseSystemOnly: false,
  value: null,
  maxHeightOptions: "288px",
  name: "carrier_select",
  disabled: false,
  required: false,
  label: "Selecciona un correo",
};

export default withStore(CarrierSelect);
